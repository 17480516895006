.App {
  text-align: center;
  }

#userTable {
  margin: auto;
}

.neg {
  color: red;
  font-size: 24px;
  font-weight: normal;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10%;
  width: 70%;
  margin-left: 15%;
}

.pos {
  color: green; 
  font-size: 24px;
  font-weight: normal;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10%;
  width: 70%;
  margin-left: 15%;
}


.userDetails {
  background-color:gainsboro;
  border-style: none;
  width: 50%;
  margin: auto;
  margin-bottom: 50px;
  border-radius: 30px;
  padding-left: 10%;
  padding-right: 10%;

} 

.posts{
  margin: auto;
  background-color: beige;
  max-width: 70%;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: beige;
  margin-bottom: 2%;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.div{
 text-align: center;
}

.Formtext{
  text-align: left;
}

.ButtonR{
  text-align: right;
}

.Scrolldown{
  float: right; 

}